import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";
import rootRouter from "../reducers";

const initialState = {};

export const store = createStore(
    rootRouter,
    initialState, composeWithDevTools(
        applyMiddleware(thunk))
);
