import React from 'react';
import Router from './router/Router';
import { store } from "./redux/store/store";
// import { refreshTokenAction } from "./redux/action/authAction";
//import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";

// Check for token
// if (localStorage.authToken) {
//   let oldToken = {
//     oldToken: localStorage.authToken
//   }
//   // Decode token and get user info and exp
//   const decoded = jwt_decode(localStorage.authToken);
//   // Check for expired token
//   const currentTime = Date.now();
//   //  console.log("currentTime",currentTime);
//   //  console.log("expTime",decoded.exp);
//   if (decoded.exp < currentTime) {
//     store.dispatch(refreshTokenAction(oldToken));
//     // Redirect to login
//     window.location.href = "/dashboard";
//   }
//   if(window.location.pathname=== "/"){
//     window.location.href = "/dashboard";
//   }
// }


  const App = () => {
    return (
      <Provider store={store}>
        <Router />
      </Provider>
    )
  }
  export default App;