export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const UNIVERSITY_ID = "d8dd4672-ae95-42bd-b515-f2065c9bb228";
export const SPORTS="";

export const POST_CONTENT_APP_STYLE = "POST_CONTENT_APP_STYLE";
export const GET_CONTENT_APP_STYLE = "GET_CONTENT_APP_STYLE";
export const PUT_CONTENT_APP_STYLE = "PUT_CONTENT_APP_STYLE";
export const DELETE_CONTENT_APP_STYLE = "DELETE_CONTENT_APP_STYLE";
export const SAVE_APP_CONTENT_DRAFT = "SAVE_APP_CONTENT_DRAFT";

export const GET_UNIVERSITY_URL = "sd/university/";
export const GET_UNIVERSITIES = "GET_UNIVERSITIES";
export const SET_UNIVERSITY = "SET_UNIVERSITY";

export const GET_ASSETS = "GET_ASSETS";

export const POST_UPLOAD_CONTENT= "POST_UPLOAD_CONTENT";
export const PUT_UPLOAD_CONTENT= "PUT_UPLOAD_CONTENT";
export const DELETE_UPLOAD_CONTENT= "DELETE_UPLOAD_CONTENT";
export const GET_UPLOAD_CONTENT= "GET_UPLOAD_CONTENT";

export const POST_AUDIO_CONTENT = "POST_AUDIO_CONTENT";

export const POST_VIDEO_UPLOAD_CONTENT= "POST_VIDEO_UPLOAD_CONTENT";
export const PUT_VIDEO_UPLOAD_CONTENT= "PUT_VIDEO_UPLOAD_CONTENT";
export const VIDEO_UPLOAD_PROGRESS= "VIDEO_UPLOAD_PROGRESS";
export const DELETE_VIDEO_UPLOAD_CONTENT= "DELETE_VIDEO_UPLOAD_CONTENT";
export const GET_VIDEO_UPLOAD_CONTENT= "GET_VIDEO_UPLOAD_CONTENT";
export const CHECK_ASSET="CHECK_ASSET"

export const POST_RECRUIT_CONTENT= "POST_RECRUIT_CONTENT";
export const PUT_RECRUIT_CONTENT= "PUT_RECRUIT_CONTENT";
export const DELETE_RECRUIT_CONTENT= "DELETE_RECRUIT_CONTENT";
export const GET_RECRUIT_CONTENT= "GET_RECRUIT_CONTENT";
export const SEARCH_RECRUIT_CONTENT= "SEARCH_RECRUIT_CONTENT";
export const WATCH_RECRUIT_VIDEOS = "WATCH_RECRUIT_VIDEOS";
export const VIDEO_CONFERENCING = "VIDEO_CONFERENCING";
export const CLIENT_VIDEO_CONFERENCING = "CLIENT_VIDEO_CONFERENCING"
export const DELETE_CONFERENCING = "DELETE_CONFERENCING";
export const ACTIVE_CONFERENCE_USERS = "ACTIVE_CONFERENCE_USERS";
export const UNREAD_MESSAGES = "UNREAD_MESSAGES";

export const POST_ANALYTICS= "POST_ANALYTICS";
export const PUT_ANALYTICS= "PUT_ANALYTICS";
export const GET_ANALYTICS= "GET_ANALYTICS";
export const GET_PUBLISHED_ANALYTICS = "GET_PUBLISHED_ANALYTICS";

export const POST_GLOBAL_ANALYTICS = "POST_GLOBAL_ANALYTICS";
export const PUT_GLOBAL_ANALYTICS = "PUT_GLOBAL_ANALYTICS";
export const GET_GLOBAL_ANALYTICS = "GET_GLOBAL_ANALYTICS";

export const POST_USERS = "POST_USERS";
export const PUT_USERS = "PUT_USERS";
export const GET_USERS = "GET_USERS";
export const DELETE_USERS = "DELETE_USERS";

export const PUT_SPORTS = "PUT_SPORTS";
export const GET_SPORTS = "GET_SPORTS";

export const PUT_HISTORY = "PUT_HISTORY";
export const GET_HISTORY = "GET_HISTORY";

export const PUT_USERS_PROFILE = "PUT_USERS_PROFILE";
export const GET_USERS_PROFILE = "GET_USERS_PROFILE";
export const POST_USERS_IMAGE = "POST_USERS_IMAGE";

export const POST_SIGNINGDAY_USERS = "POST_SIGNINGDAY_USERS";
export const PUT_SIGNINGDAY_USERS = "PUT_SIGNINGDAY_USERS";
export const GET_SIGNINGDAY_USERS = "GET_SIGNINGDAY_USERS";
export const DELETE_SIGNINGDAY_USERS = "DELETE_SIGNINGDAY_USERS";

export const POST_UNIVERSITY_MANAGEMENT = "POST_UNIVERSITY_MANAGEMENT";
export const PUT_UNIVERSITY_MANAGEMENT = "PUT_UNIVERSITY_MANAGEMENT";
export const GET_UNIVERSITY_MANAGEMENT = "GET_UNIVERSITY_MANAGEMENT";
export const DELETE_UNIVERSITY_MANAGEMENT = "DELETE_UNIVERSITY_MANAGEMENT";

export const GET_MESSAGES = "GET_MESSAGES";
export const POST_MESSAGES = "POST_MESSAGES";
export const GET_MESSAGE_HISTORY = "GET_MESSAGE_HISTORY";
export const GET_NEW_MESSAGE = "GET_NEW_MESSAGE";

export const UPDATE_CHAT_STATUS = "UPDATE_CHAT_STATUS";
export const GET_CHAT_STATUS = "GET_CHAT_STATUS";
export const UPDATE_UNIVERSITY_ICON = "UPDATE_UNIVERSITY_ICON";
export const GET_UNIVERSITY_ICON = "GET_UNIVERSITY_ICON";
export const UPDATE_UNIVERSITY_BACKGROUND = "UPDATE_UNIVERSITY_BACKGROUND";
export const GET_UNIVERSITY_BACKGROUND = "GET_UNIVERSITY_BACKGROUND";

export const POST_EDITORIAL_CONTENT = "POST_EDITORIAL_CONTENT";
export const PUT_EDITORIAL_CONTENT = "PUT_EDITORIAL_CONTENT";
export const GET_EDITORIAL_CONTENT = "GET_EDITORIAL_CONTENT";
export const DELETE_EDITORIAL_CONTENT = "DELETE_EDITORIAL_CONTENT";

