import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTRATION_SUCCESS,
  RESET_PASSWORD,
  FORGET_PASSWORD,
} from "../constants/constant";

const initialState = {
    isAuthenticated: false
};

export default function (state = initialState, action) {
    switch (action.type) {
      case LOGIN_SUCCESS:
        return {
          ...state,
          isAuthenticated: true,
          loginResponse: action.payload,
        };

      case LOGOUT_SUCCESS:
        return {
          ...state,
          isAuthenticated: false,
          loginResponse: null,
        };

      case REGISTRATION_SUCCESS:
        return {
          ...state,
          registrationResponse: action.payload,
        };

      case RESET_PASSWORD:
        return {
          ...state,
          resetPasswordResponse: action.payload,
        };
      case FORGET_PASSWORD:
        return {
          ...state,
          forgetPasswordResponse: action.payload,
        };
      default:
        return state;
    }
}