import React from "react";
import Spinner from "./spinner.gif";

export default ()=>{
    return(
        <div>
            <img 
                src={Spinner}
                style={{width:"100px",margin:"auto", display:"flex",background:"transparent",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginLeft: "-50px",
                marginTop: "-50px"}}
                alt="Loading..."
            />
        </div>
    )
}