import {
  UPDATE_CHAT_STATUS,
  GET_CHAT_STATUS,
  UPDATE_UNIVERSITY_ICON,
  GET_UNIVERSITY_ICON,
  UPDATE_UNIVERSITY_BACKGROUND,
  GET_UNIVERSITY_BACKGROUND,
} from "../constants/constant";

const initialState = {
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_CHAT_STATUS:
      return {
        ...state,
        updateChatResponse: action.payload,
      };
    case GET_CHAT_STATUS:
      return {
        ...state,
        getChatResponse: action.payload,
      };
    case UPDATE_UNIVERSITY_ICON:
      return {
        ...state,
        updateIconResponse: action.payload,
      };
    case GET_UNIVERSITY_ICON:
      return {
        ...state,
        getIconResponse: action.payload,
      };
    case UPDATE_UNIVERSITY_BACKGROUND:
      return {
        ...state,
        updateBackgroundResponse: action.payload,
      };
    case GET_UNIVERSITY_BACKGROUND:
      return {
        ...state,
        getBackgroundResponse: action.payload,
      };
    default:
      return state;
  }
}
