import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Spinner from "../components/common/Spinner";
import Env from "../api/env";

const ForgetPassword = React.lazy(() =>
  import("../components/auth/ForgetPassword")
);
const RecoverPassword = React.lazy(() =>
  import("../components/auth/RecoverPassword")
);
const Login = React.lazy(() => import("../components/auth/Login"));
const Registration = React.lazy(() =>
  import("../components/auth/Registration")
);
const AccessError = React.lazy(() =>
  import("../components/accessError/AccessError")
);
const Dashboard = React.lazy(() => import("../components/dashboard/Dashboard"));
const UploadContent = React.lazy(() =>
  import("../components/uploadContent/UploadContent")
);
const Recruits = React.lazy(() => import("../components/recruits/Recruits"));
const Analytics = React.lazy(() => import("../components/analytics/Analytics"));
const Users = React.lazy(() => import("../components/users/Users"));
const ManageContent = React.lazy(() =>
  import("../components/manageContent/ManageContent")
);
const University = React.lazy(() =>
  import("../components/university/University")
);
const GlobalAnalytics = React.lazy(() =>
  import("../components/globalAnalytics/GlobalAnalytics")
);
const UniversitySetting = React.lazy(() =>
  import("../components/universitySetting/UniversitySetting")
);
const SigningDayUserAccess = React.lazy(() =>
  import("../components/signingDayUserAccess/SigningDayUserAccess")
);
const UploadApplicationContent = React.lazy(() =>
  import("../components/uploadApplicationContent/UploadApplicationContent")
);
const ManageApplicationContent = React.lazy(() =>
  import("../components/manageApplicationContent/ManageApplicationContent")
);
const EditorialContent = React.lazy(() =>
  import("../components/editorialContent/EditorialContent")
);
const SportsEvent = React.lazy(() =>
  import("../components/sportSchedule/SportSchedule")
);
const ProgramHistory = React.lazy(() =>
  import("../components/programHistory/ProgramHistory")
);




class Router extends Component {
  render() {
    const routerProps = Env.directory ? { basename: Env.directory } : {};

    return (
      <BrowserRouter {...routerProps}>
        <React.Suspense fallback={<Spinner />}>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route exact path="/forget-password">
              <ForgetPassword />
            </Route>
            <Route exact path="/recover-password">
              <RecoverPassword />
            </Route>
            <Route exact path="/registration">
              <Registration />
            </Route>
            <Route exact path="/dashboard">
              <Dashboard />
            </Route>
            <Route exact path="/upload-content">
              <UploadContent />
            </Route>
            <Route exact path="/manage-content">
              <ManageContent />
            </Route>
            <Route exact path="/recruits">
              <Recruits />
            </Route>
            <Route exact path="/analytics">
              <Analytics />
            </Route>
            <Route exact path="/users">
              <Users />
            </Route>
            <Route exact path="/accessError">
              <AccessError />
            </Route>
            <Route exact path="/university">
              <University />
            </Route>
            <Route exact path="/global-analytics">
              <GlobalAnalytics />
            </Route>
            <Route exact path="/university-settings">
              <UniversitySetting />
            </Route>
            <Route exact path="/signingday-users">
              <SigningDayUserAccess />
            </Route>
            <Route exact path="/upload-application-Content">
              <UploadApplicationContent />
            </Route>
            <Route exact path="/manage-application-Content">
              <ManageApplicationContent />
            </Route>
            <Route exact path="/editorial-content">
              <EditorialContent />
            </Route>
            <Route exact path="/sports-schedule">
              <SportsEvent />
            </Route>
            <Route exact path="/program-history">
              <ProgramHistory />
            </Route>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default Router;
