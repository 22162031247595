import {
    GET_SPORTS,
    PUT_SPORTS,
} from "../constants/constant";

const initialState = {
    isAuthenticated: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SPORTS:
            return {
                ...state,
                getSportResponse: action.payload,
            };
        case PUT_SPORTS:
            return {
                ...state,
                putSportResponse: action.payload,
            };
        default:
            return state;
    }
}
