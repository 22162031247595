import {
  POST_USERS,
  GET_USERS,
  PUT_USERS,
  DELETE_USERS
} from "../constants/constant";

const initialState = {
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POST_USERS:
      return {
        ...state,
        postUsersResponse: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        getUsersResponse: action.payload,
      };
    case PUT_USERS:
      return {
        ...state,
        putUsersResponse: action.payload,
      };
    case DELETE_USERS:
      return {
        ...state,
        deleteUsersResponse: action.payload,
      };
    default:
      return state;
  }
}
