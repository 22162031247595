import {
  POST_GLOBAL_ANALYTICS,
  GET_GLOBAL_ANALYTICS,
  PUT_GLOBAL_ANALYTICS,
} from "../constants/constant";

const initialState = {
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POST_GLOBAL_ANALYTICS:
      return {
        ...state,
        postGlobalAnalyticsResponse: action.payload,
      };
    case GET_GLOBAL_ANALYTICS:
      return {
        ...state,
        getGlobalAnalyticsResponse: action.payload,
      };
    case PUT_GLOBAL_ANALYTICS:
      return {
        ...state,
        putGlobalAnalyticsResponse: action.payload,
      };

    default:
      return state;
  }
}
