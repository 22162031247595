import {
  PUT_USERS_PROFILE,
  GET_USERS_PROFILE,
  POST_USERS_IMAGE,
} from "../constants/constant";

const initialState = {
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS_PROFILE:
      return {
        ...state,
        getUserProfileResponse: action.payload,
      };
    case PUT_USERS_PROFILE:
      return {
        ...state,
        putUserProfileResponse: action.payload,
      };
    case POST_USERS_IMAGE:
      return {
        ...state,
        postUserImageResponse: action.payload,
      };

    default:
      return state;
  }
}
