import {
  GET_ASSETS,
} from "../constants/constant";

const initialState = {
  assets: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ASSETS:
      return {
        ...state,
        assets: action.payload,
      };
    
    default:
      return state;
  }
}
