import {
  POST_SIGNINGDAY_USERS,
  GET_SIGNINGDAY_USERS,
  PUT_SIGNINGDAY_USERS,
  DELETE_SIGNINGDAY_USERS,
} from "../constants/constant";

const initialState = {
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POST_SIGNINGDAY_USERS:
      return {
        ...state,
        postSigningDayUsersResponse: action.payload,
      };
    case GET_SIGNINGDAY_USERS:
      return {
        ...state,
        getSigningDayUsersResponse: action.payload,
      };
    case PUT_SIGNINGDAY_USERS:
      return {
        ...state,
        putSigningDayUsersResponse: action.payload,
      };
    case DELETE_SIGNINGDAY_USERS:
      return {
        ...state,
        deleteSigningDayUsersResponse: action.payload,
      };
    default:
      return state;
  }
}
