import {
  POST_UPLOAD_CONTENT,
  PUT_UPLOAD_CONTENT,
  DELETE_UPLOAD_CONTENT,
  GET_UPLOAD_CONTENT,
  POST_VIDEO_UPLOAD_CONTENT,
  VIDEO_UPLOAD_PROGRESS,
  PUT_VIDEO_UPLOAD_CONTENT,
  DELETE_VIDEO_UPLOAD_CONTENT,
  GET_VIDEO_UPLOAD_CONTENT,
  CHECK_ASSET,
  POST_AUDIO_CONTENT,
} from "../constants/constant";
  
  const initialState = {
    isAuthenticated: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case POST_UPLOAD_CONTENT:
        return {
          ...state,
          postUploadContentResponse: action.payload,
        };

      case GET_UPLOAD_CONTENT:
        return {
          ...state,
          getUploadContentResponse: action.payload,
        };

      case PUT_UPLOAD_CONTENT:
        return {
          ...state,
          putUploadContentResponse: action.payload,
        };
      case DELETE_UPLOAD_CONTENT:
        return {
          ...state,
          deleteUploadContentResponse: action.payload,
        };
      case POST_VIDEO_UPLOAD_CONTENT:
        return {
          ...state,
          postVideoContentResponse: action.payload,
        };
      case VIDEO_UPLOAD_PROGRESS:
        return {
          ...state,
          videoUploadProgress: action.payload,
          postVideoContentResponse: null,
        };
      case GET_VIDEO_UPLOAD_CONTENT:
        return {
          ...state,
          getUploadContentResponse: action.payload,
        };

      case PUT_VIDEO_UPLOAD_CONTENT:
        return {
          ...state,
          putUploadContentResponse: action.payload,
          videoUploadProgress: null,
        };
      case DELETE_VIDEO_UPLOAD_CONTENT:
        return {
          ...state,
          deleteUploadContentResponse: action.payload,
        };
      case CHECK_ASSET:
        return {
          ...state,
          checkAssetResponse: action.payload,
        };
      case POST_AUDIO_CONTENT:
        return {
          ...state,
          postAudioResponse: action.payload,
        };

      default:
        return state;
    }
  }
  