class Environment {
  constructor() {

    this.client = "Web";
    this.app = "UniversityPortal";
    this.sdApplicationId = "8622df10-a091-4f51-954f-119723afb120";

    this.videoConferencingEnabled = false;
    
    
    /** Configure the backend environment. */
    //this.setBackendEnvironment("dev", "http://localhost");
    //this.setBackendEnvironment("dev", "http://172.16.10.30");
    //this.setBackendEnvironment("sandbox");
    this.setBackendEnvironment("production");
    //this.setBackendEnvironment("production-test");
    
    /** Configure the web server environment. */
    //this.setWebEnvironment("dev", "http://localhost");
    // this.setWebEnvironment("dev", "http://172.16.10.30");
    //this.setWebEnvironment("sandbox");
    this.setWebEnvironment("production");
    //this.setWebEnvironment("production-test");
      
  }
  
  setWebEnvironment(env, host) {
    if( env === "dev" ) {
      this.host = host;
      this.corsHost = "http://localhost:3000";
      this.directory = null;
      this.appHome = this.host + (this.directory? "/signingday" : "");
    }
    else if( env === "sandbox") {
      this.host = 'https://sandbox-diogras.com';
      this.corsHost = this.host;
      this.directory = "signingday";
      this.appHome = this.host + "/" + this.directory;
    }
    else if( env === "production") {
      this.host = 'https://portal.coachesvue.com';
      this.corsHost = this.host;
      this.directory = null;
      this.appHome = this.host + (this.directory? "/signingday" : "");
    }
    else if( env === "production-test") {
      this.host = 'https://cvtest.sandbox-diogras.com';
      this.corsHost = this.host;
      this.directory = null;
      this.appHome = this.host + (this.directory? "/signingday" : "");
    }
  }

  setBackendEnvironment(env, baseURI) {
    this.backendEnvironment = env;

    if (env === "dev") {
      this.baseURI = baseURI;     
      this.authURI = this.baseURI + ":9001/auth/";
      this.chatURI = this.baseURI + ":9003/chat/";
      this.cmsURI = this.baseURI + ":9008/cmss/";
      this.notifURI = this.baseURI + ":9007/notif/";
      this.sdURI = this.baseURI + ":9102/sd/";
      this.analyticURI = this.baseURI + ":9109/analytics/";

      this.s3BaseURI = 'https://diogras-sandbox.s3-us-west-2.amazonaws.com/';
      this.useTestVideos = true;
    }
    else if (env === "sandbox") {
      this.baseURI = 'https://sandbox-diogras.com/sdapi/';
      this.authURI = this.baseURI + "auth/";
      this.chatURI = this.baseURI + "chat/";
      this.cmsURI = this.baseURI + "cmss/";
      this.notifURI = this.baseURI + "notif/";
      this.sdURI = this.baseURI + "sd/";
      this.analyticURI = this.baseURI + "analytics/";

      this.s3BaseURI = 'https://diogras-sandbox.s3-us-west-2.amazonaws.com/';
      this.useTestVideos = false;
    }
    else if (env === "production") {
      this.baseURI = 'https://portal.coachesvue.com/sdapi/';
      this.authURI = this.baseURI + "auth/";
      this.chatURI = this.baseURI + "chat/";
      this.cmsURI = this.baseURI + "cmss/";
      this.notifURI = this.baseURI + "notif/";
      this.sdURI = this.baseURI + "sd/";
      this.analyticURI = this.baseURI + "analytics/";

      this.s3BaseURI = 'https://signingdaycms.s3-us-west-2.amazonaws.com/';
      this.useTestVideos = false;
    }
    else if (env === "production-test") {
      this.baseURI = 'https://cvtest.sandbox-diogras.com/sdapi/';
      this.authURI = this.baseURI + "auth/";
      this.chatURI = this.baseURI + "chat/";
      this.cmsURI = this.baseURI + "cmss/";
      this.notifURI = this.baseURI + "notif/";
      this.sdURI = this.baseURI + "sd/";
      this.analyticURI = this.baseURI + "analytics/";

      this.s3BaseURI = 'https://signingdaycms.s3-us-west-2.amazonaws.com/';
      this.useTestVideos = false;
    }
  }
}



const Env = new Environment();

export default Env;