import {
  POST_CONTENT_APP_STYLE,
  GET_CONTENT_APP_STYLE,
  PUT_CONTENT_APP_STYLE,
  DELETE_CONTENT_APP_STYLE,
  SAVE_APP_CONTENT_DRAFT,
} from "../constants/constant";

const initialState = {
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POST_CONTENT_APP_STYLE:
      return {
        ...state,
        postContentAppStyleResponse: action.payload,
      };

    case GET_CONTENT_APP_STYLE:
      return {
        ...state,
        getContentAppStyleResponse: action.payload,
      };
      
    case PUT_CONTENT_APP_STYLE:
      return {
        ...state,
        putContentAppStyleResponse: action.payload,
      };
    case DELETE_CONTENT_APP_STYLE:
      return {
        ...state,
        deleteContentAppStyleResponse: action.payload,
      };
    case SAVE_APP_CONTENT_DRAFT:
      return {
        ...state,
        putContentAppStyleResponse: action.payload,
      };
    default:
      return state;
  }
}
