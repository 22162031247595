import {
  POST_UNIVERSITY_MANAGEMENT,
  GET_UNIVERSITY_MANAGEMENT,
  PUT_UNIVERSITY_MANAGEMENT,
  DELETE_UNIVERSITY_MANAGEMENT,
} from "../constants/constant";

const initialState = {
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POST_UNIVERSITY_MANAGEMENT:
      return {
        ...state,
        postUniversityManagementResponse: action.payload,
      };
    case GET_UNIVERSITY_MANAGEMENT:
       
      return {
        ...state,
        getUniversityManagementResponse: action.payload,
      };
    case PUT_UNIVERSITY_MANAGEMENT:
      return {
        ...state,
        putUniversityManagementResponse: action.payload,
      };
    case DELETE_UNIVERSITY_MANAGEMENT:
      return {
        ...state,
        deleteUniversityManagementResponse: action.payload,
      };
    default:
      return state;
  }
}
