import {
  POST_EDITORIAL_CONTENT,
  GET_EDITORIAL_CONTENT,
  PUT_EDITORIAL_CONTENT,
  DELETE_EDITORIAL_CONTENT,
} from "../constants/constant";

const initialState = {
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POST_EDITORIAL_CONTENT:
      return {
        ...state,
        postEditorialResponse: action.payload,
      };
    case GET_EDITORIAL_CONTENT:
      return {
        ...state,
        getEditorialResponse: action.payload,
      };
    case PUT_EDITORIAL_CONTENT:
      return {
        ...state,
        putEditorialResponse: action.payload,
      };
    case DELETE_EDITORIAL_CONTENT:
      return {
        ...state,
        deleteEditorialResponse: action.payload,
      };
    default:
      return state;
  }
}
