import {
  GET_UNIVERSITIES,
  SET_UNIVERSITY,
} from "../constants/constant";

const initialState = {
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_UNIVERSITIES:
      return {
        ...state,
        availableUniversities: action.payload.universities,
        currentUniversityId: action.payload.currentUniversityId,
      };

    case SET_UNIVERSITY:
      return {
        ...state,
        currentUniversityId: action.payload.currentUniversityId,
      };
    
    default:
      return state;
  }
}
