import {combineReducers } from "redux";
import authReducer from "./authReducer";
import appContentReducer from "./appContentReducer";
import uploadContentReducer from "./uploadContentReducer"
import universityReducer from "./universityReducer"
import cmsReducer from "./cmsReducer"
import recruitReducer from "./recruitReducrer"
import analyticsReducer from "./analyticsReducer"
import usersReducer from "./usersReducer"
import universityManagementReducer from "./universityManagementReducer"
import chatBotReducer from "./chatBotReducer"
import globalAnalyticsReducer from "./globalAnalyticsReducer";
import universitySettingReducer from "./universitySettingReducer";
import signingDayUsersReducer from "./signingDayUsersReducer";
import userProfileReducer from "./userProfileReducer"
import editorialContenReducer from "./editorialContenReducer";
import sportScheduleReducer from "./sportScheduleReducer";
import historyReducer from "./historyReducer";
export default combineReducers({
  auth: authReducer,
  appContent: appContentReducer,
  uploadContent: uploadContentReducer,
  university: universityReducer,
  cms: cmsReducer,
  recruit: recruitReducer,
  analytics: analyticsReducer,
  users: usersReducer,
  universityManagement: universityManagementReducer,
  chatBot: chatBotReducer,
  globalAnalytics: globalAnalyticsReducer,
  updateSetting: universitySettingReducer,
  signingDayUsers: signingDayUsersReducer,
  userProfile: userProfileReducer,
  editorial: editorialContenReducer,
  sport: sportScheduleReducer,
  history: historyReducer,
});
