import {
  POST_RECRUIT_CONTENT,
  GET_RECRUIT_CONTENT,
  PUT_RECRUIT_CONTENT,
  DELETE_RECRUIT_CONTENT,
  SEARCH_RECRUIT_CONTENT,
  WATCH_RECRUIT_VIDEOS,
  VIDEO_CONFERENCING,
  CLIENT_VIDEO_CONFERENCING,
  ACTIVE_CONFERENCE_USERS,
  UNREAD_MESSAGES,
} from "../constants/constant";

const initialState = {
    isAuthenticated: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
      case POST_RECRUIT_CONTENT:
        return {
          ...state,
          postRecruitResponse: action.payload,
        };
      case GET_RECRUIT_CONTENT:
        return {
          ...state,
          getRecruitResponse: action.payload,
        };
      case PUT_RECRUIT_CONTENT:
        return {
          ...state,
          putRecruitResponse: action.payload,
        };
      case DELETE_RECRUIT_CONTENT:
        return {
          ...state,
          deleteRecruitResponse: action.payload,
        };
      case SEARCH_RECRUIT_CONTENT:
        return {
          ...state,
          searchRecruitResponse: action.payload,
        };
      case WATCH_RECRUIT_VIDEOS:
        return {
          ...state,
          watchVideosResponse: action.payload,
        };
      case VIDEO_CONFERENCING:
        return {
          ...state,
          videoConferencingResponse: action.payload,
        };
      case CLIENT_VIDEO_CONFERENCING:
        return {
          ...state,
          clientConferencingResponse: action.payload,
        };
      case ACTIVE_CONFERENCE_USERS:
        return {
          ...state,
          activeConferenceUsers: action.payload,
        };

      case UNREAD_MESSAGES:
        return {
          ...state,
          unreadMessages: action.payload,
        };
      default:
        return state;
    }
}
