import {
  GET_MESSAGES,
  GET_MESSAGE_HISTORY,
  POST_MESSAGES,
  GET_NEW_MESSAGE,
} from "../constants/constant";

const initialState = {
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POST_MESSAGES:
      return {
        ...state,
        postMessagesResponse: action.payload,
      };
    case GET_MESSAGES:
      return {
        ...state,
        getMessageResponse: action.payload,
      };

    case GET_MESSAGE_HISTORY:
      return {
        ...state,
        getMessageHistoryResponse: action.payload,
      };
    case GET_NEW_MESSAGE:
      return {
        ...state,
        getNewMessageResponse: action.payload,
      };

    default:
      return state;
  }
}
