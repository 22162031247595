import {
  POST_ANALYTICS,
  GET_ANALYTICS,
  PUT_ANALYTICS,
  GET_PUBLISHED_ANALYTICS,
} from "../constants/constant";

const initialState = {
    isAuthenticated: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
      case POST_ANALYTICS:
        return {
          ...state,
          postAnalyticsResponse: action.payload,
        };
      case GET_ANALYTICS:
        return {
          ...state,
          getAnalyticsResponse: action.payload,
        };
      case PUT_ANALYTICS:
        return {
          ...state,
          putAnalyticsResponse: action.payload,
        };
      case GET_PUBLISHED_ANALYTICS:
        return {
          ...state,
          getPublishedAnalyticsResponse: action.payload,
        };
      default:
        return state;
    }
}
